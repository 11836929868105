import React from "react";
import AllDisplay from "../../component/display/AllDisplay";
function AllDisplayPage() {
  return (
    <div>
      <AllDisplay />
    </div>
  );
}

export default AllDisplayPage;
