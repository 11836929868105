import React from "react";
import "./FooterStyle.css";
import { Container, Row, Col, Image } from "react-bootstrap";
import ABA from "../../assets/img/aba.png";
import KHQR from "../../assets/img/khqr.png";
function FooterComponent() {
  return (
    <div>
      <div className="bgFooter">
        <div className="screen-992">
          <Container className="contwidth">
            <Row>
              <Col>
                <span className="copystyle">
                  &copy; Compyright 2024 by www.sortadiamondstore.com
                </span>
                <span className="imgPay">
                  Payment Accept:&nbsp;
                  <Image src={ABA} className="imgfoot" />
                  <Image src={KHQR} className="imgfoot" />
                </span>
              </Col>
            </Row>
          </Container>
        </div>
        <div className="toogle-992">
          <Container className="contwidth">
            <Row>
              <Col lg={12} className="text-center">
                <span>&copy; Compyright 2024 by www.sortadiamondstore.com</span>
              </Col>
              <Col lg={12} className="text-center">
                <span>
                  Payment Accept:&nbsp;
                  <Image src={ABA} className="imgfoot" />
                  <Image src={KHQR} className="imgfoot" />
                </span>
              </Col>
            </Row>
          </Container>
        </div>
      </div>
    </div>
  );
}

export default FooterComponent;
