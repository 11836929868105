import React, { useState, useEffect } from "react";
import axios from "axios";
import { Container, Row, Col, Card, Form, InputGroup } from "react-bootstrap";
import { Search } from "react-bootstrap-icons";
import M1 from "../../assets/proImg/allgame/m1.jpg";
import M2 from "../../assets/proImg/allgame/m2.jpg";
import M3 from "../../assets/proImg/allgame/m3.jpg";
import M4 from "../../assets/proImg/allgame/m4.jpg";
import "./MobilecomStyle.css";
function MobileComponent() {
  const server = "https://moogold.com/wp-json/v1/api/";
  const [list, setList] = useState([]);
  useEffect(() => {
    getList();
  }, []);
  const getList = () => {
    axios({
      url: server + "order/create_order",
      method: "post",
      headers: {
        Authorization:
          "Basic eXVzb3J0YTAyQGdtYWlsLmNvbTp5dXNvcnRhMDJAZ21haWwuY29t",
        auth: "eXVzb3J0YTAyQGdtYWlsLmNvbTp5dXNvcnRhMDJAZ21haWwuY29t",
        //timestamp: "current_unix_timestamp",
      },
    })
      .then((res) => {
        var data = res.data;
        setList(data.list);
      })
      .catch((err) => {
        console.log(err);
      });
  };
  return (
    <div>
      <Container className="contwidth mt-3">
        <Row>
          <Col lg={6} md={6} xs={12}>
            <span className="spanAllGame">MOBILE GAMES</span>
          </Col>
          <Col lg={6} md={6} xs={12}>
            <span className="floatRight">
              <InputGroup className="mb-3">
                <Form.Control
                  placeholder="Find Mobile Game"
                  aria-label="Find Mobile Game"
                  aria-describedby="basic-addon2"
                  className="form-control"
                />
                <InputGroup.Text id="basic-addon2" className="form-btn">
                  <Search />
                </InputGroup.Text>
              </InputGroup>
            </span>
          </Col>
        </Row>
        <Row>
          <Col xxl={3} xl={3} lg={4} md={6} sm={6} xs={6}>
            <Card className="cardDisplay grow">
              <Card.Img variant="top" src={M1} />
              <Card.Body>
                <Card.Text>Some quick example text to build</Card.Text>
              </Card.Body>
            </Card>
          </Col>
          <Col xxl={3} xl={3} lg={4} md={6} sm={6} xs={6}>
            <Card className="cardDisplay grow">
              <Card.Img variant="top" src={M1} />
              <Card.Body>
                <Card.Text>Some quick example text to build</Card.Text>
              </Card.Body>
            </Card>
          </Col>
          <Col xxl={3} xl={3} lg={4} md={6} sm={6} xs={6}>
            <Card className="cardDisplay grow">
              <Card.Img variant="top" src={M1} />
              <Card.Body>
                <Card.Text>Some quick example text to build</Card.Text>
              </Card.Body>
            </Card>
          </Col>
          <Col xxl={3} xl={3} lg={4} md={6} sm={6} xs={6}>
            <Card className="cardDisplay grow">
              <Card.Img variant="top" src={M2} />
              <Card.Body>
                <Card.Text>Some quick example text to build</Card.Text>
              </Card.Body>
            </Card>
          </Col>
          <Col xxl={3} xl={3} lg={4} md={6} sm={6} xs={6}>
            <Card className="cardDisplay grow">
              <Card.Img variant="top" src={M3} />
              <Card.Body>
                <Card.Text>Some quick example text to build</Card.Text>
              </Card.Body>
            </Card>
          </Col>
          <Col xxl={3} xl={3} lg={4} md={6} sm={6} xs={6}>
            <Card className="cardDisplay grow">
              <Card.Img variant="top" src={M4} />
              <Card.Body>
                <Card.Text>Some quick example text to build</Card.Text>
              </Card.Body>
            </Card>
          </Col>
          <Col xxl={3} xl={3} lg={4} md={6} sm={6} xs={6}>
            <Card className="cardDisplay grow">
              <Card.Img variant="top" src={M1} />
              <Card.Body>
                <Card.Text>Some quick example text to build</Card.Text>
              </Card.Body>
            </Card>
          </Col>
          <Col xxl={3} xl={3} lg={4} md={6} sm={6} xs={6}>
            <Card className="cardDisplay grow">
              <Card.Img variant="top" src={M1} />
              <Card.Body>
                <Card.Text>Some quick example text to build</Card.Text>
              </Card.Body>
            </Card>
          </Col>
          <Col xxl={3} xl={3} lg={4} md={6} sm={6} xs={6}>
            <Card className="cardDisplay grow">
              <Card.Img variant="top" src={M1} />
              <Card.Body>
                <Card.Text>Some quick example text to build</Card.Text>
              </Card.Body>
            </Card>
          </Col>
          <Col xxl={3} xl={3} lg={4} md={6} sm={6} xs={6}>
            <Card className="cardDisplay grow">
              <Card.Img variant="top" src={M2} />
              <Card.Body>
                <Card.Text>Some quick example text to build</Card.Text>
              </Card.Body>
            </Card>
          </Col>
          <Col xxl={3} xl={3} lg={4} md={6} sm={6} xs={6}>
            <Card className="cardDisplay grow">
              <Card.Img variant="top" src={M3} />
              <Card.Body>
                <Card.Text>Some quick example text to build</Card.Text>
              </Card.Body>
            </Card>
          </Col>
          <Col xxl={3} xl={3} lg={4} md={6} sm={6} xs={6}>
            <Card className="cardDisplay grow">
              <Card.Img variant="top" src={M4} />
              <Card.Body>
                <Card.Text>Some quick example text to build</Card.Text>
              </Card.Body>
            </Card>
          </Col>
          <Col xxl={3} xl={3} lg={4} md={6} sm={6} xs={6}>
            <Card className="cardDisplay grow">
              <Card.Img variant="top" src={M1} />
              <Card.Body>
                <Card.Text>Some quick example text to build</Card.Text>
              </Card.Body>
            </Card>
          </Col>
          <Col xxl={3} xl={3} lg={4} md={6} sm={6} xs={6}>
            <Card className="cardDisplay grow">
              <Card.Img variant="top" src={M1} />
              <Card.Body>
                <Card.Text>Some quick example text to build</Card.Text>
              </Card.Body>
            </Card>
          </Col>
          <Col xxl={3} xl={3} lg={4} md={6} sm={6} xs={6}>
            <Card className="cardDisplay grow">
              <Card.Img variant="top" src={M1} />
              <Card.Body>
                <Card.Text>Some quick example text to build</Card.Text>
              </Card.Body>
            </Card>
          </Col>
          <Col xxl={3} xl={3} lg={4} md={6} sm={6} xs={6}>
            <Card className="cardDisplay grow">
              <Card.Img variant="top" src={M2} />
              <Card.Body>
                <Card.Text>Some quick example text to build</Card.Text>
              </Card.Body>
            </Card>
          </Col>
          <Col xxl={3} xl={3} lg={4} md={6} sm={6} xs={6}>
            <Card className="cardDisplay grow">
              <Card.Img variant="top" src={M3} />
              <Card.Body>
                <Card.Text>Some quick example text to build</Card.Text>
              </Card.Body>
            </Card>
          </Col>
          <Col xxl={3} xl={3} lg={4} md={6} sm={6} xs={6}>
            <Card className="cardDisplay grow">
              <Card.Img variant="top" src={M4} />
              <Card.Body>
                <Card.Text>Some quick example text to build</Card.Text>
              </Card.Body>
            </Card>
          </Col>
        </Row>
      </Container>
    </div>
  );
}

export default MobileComponent;
