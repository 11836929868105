import React from "react";
import { Carousel, Container, Image } from "react-bootstrap";
import Img1 from "../../assets/proImg/slide/img1.jpg";
import Img2 from "../../assets/proImg/slide/img2.jpg";
import "./SlideStyle.css";

function SlideComponent() {
  return (
    <div>
      <Container className="contwidth">
        <Carousel>
          <Carousel.Item>
            <Image src={Img1} className="sliderStyle" />
          </Carousel.Item>
          <Carousel.Item>
            <Image src={Img2} className="sliderStyle" />
          </Carousel.Item>
        </Carousel>
      </Container>
    </div>
  );
}

export default SlideComponent;
