import React, { useState, useEffect } from "react";
import { requestMooGoldToken } from "../../share/mooGoldRequest";
import { useParams } from "react-router-dom";
import { Form, Button, Input, message } from "antd";
import { Container, Row, Col, Card, Image } from "react-bootstrap";
import ABA from "../../assets/img/aba.png";
import Khqr from "../../assets/img/khqr.png";
import "./AlldisStyle.css";
function AllDisplay() {
  const [list, setList] = useState([]);
  const { id } = useParams();
  const [activeButton, setActiveButton] = useState(0);
  const [price, setPrice] = useState(0);
  const [value, setValue] = useState(0);
  const [uID, setUid] = useState("");
  const [form] = Form.useForm();
  const [isDisabled, setIsDisabled] = useState(true);
  const [checked, setChecked] = useState(false);

  useEffect(() => {
    fetchData();
    getUUID();
  }, []);
  const canBeSubmitted = () => {
    return checked ? setIsDisabled(true) : setIsDisabled(false);
  };

  const onCheckboxClick = () => {
    setChecked(!checked);
    return canBeSubmitted();
  };
  const fetchData = async () => {
    try {
      var url = "product/product_detail"; //"product/product_detail";
      var requestBody = JSON.stringify({
        path: "product/product_detail",
        product_id: id,
      });
      const result = await requestMooGoldToken(url, "POST", requestBody, null);
      setList(result);
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };
  const handleSubmit = async (item) => {
    try {
      var url = "order/create_order"; //"product/product_detail";
      var requestBody = JSON.stringify({
        path: "order/create_order",
        data: {
          category: "50",
          "product-id": activeButton,
          quantity: value,
          "User ID": item.userid,
          "Server ID": item.server,
        },
        partnerOrderId: uID,
      });
      const result = await requestMooGoldToken(url, "POST", requestBody, null);
      setList(result);
      form.resetFields();
      fetchData();
      getUUID();
      message.success(result.message);
      setValue("0");
      document.getElementById("checkbox").checked = false;
      canBeSubmitted();
    } catch (error) {
      console.error("Error fetching data:", error);
      message.warning("Please order againt!");
    }
  };
  const handleInputChange = (event) => {
    const value = event.target.value;
    setValue(value);
  };
  const generateUUID = () => {
    // Generate a UUID using a simple function
    return "xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx".replace(
      /[xy]/g,
      function (c) {
        var r = (Math.random() * 16) | 0,
          v = c === "x" ? r : (r & 0x3) | 0x8;
        return v.toString(16);
      }
    );
  };

  const getUUID = () => {
    const genID = generateUUID();
    setUid(genID);
  };

  return (
    <div>
      <Container className="contwidth mt-3">
        <Card className="cardDisplay cardborder">
          <Card.Body>
            <Row>
              <Col xxl={5} lg={5} md={5} sm={12} xs={12}>
                {list.Image_URL ? <Card.Img src={list.Image_URL} /> : <p></p>}
              </Col>
              <Col xxl={7} lg={7} md={7} sm={12} xs={12}>
                {list.Product_Name ? (
                  <div>
                    <p style={{ textAlign: "center", fontSize: 24 }}>
                      {list.Product_Name}
                    </p>
                    <h6>
                      Welcome to you my our service. Please find and top up all
                      package that you need and selected for payment. Thank your
                      for all customer!{" "}
                    </h6>
                  </div>
                ) : (
                  <p></p>
                )}
              </Col>
            </Row>
          </Card.Body>
        </Card>
      </Container>
      <Container className="contwidth mt-3">
        <Row>
          <Col xxl={12} lg={12} md={12} sm={12} xs={12}>
            <Card className="cardDisplay cardborder">
              <Card.Header className="cardheader">
                PLEASE SELECT PACKAGE
              </Card.Header>
              <Card.Body>
                <Row>
                  {list.Variation ? (
                    list.Variation.map(function (item, index) {
                      return (
                        <Col xxl={4} lg={4} md={6} sm={6} xs={6} key={index}>
                          <Button
                            className={`btnStyle ${
                              activeButton === item.variation_id
                                ? "btn-success"
                                : null
                            }`}
                            value={item.variation_id}
                            onClick={() => {
                              setActiveButton(item.variation_id);
                              setPrice(item.variation_price);
                              setValue("1");
                            }}
                          >
                            <span
                              style={{
                                fontSize: 12,
                                textAlign: "center",
                                marginTop: 4,
                              }}
                              dangerouslySetInnerHTML={{
                                __html: item.variation_name
                                  .split("-")[1]
                                  .trim()
                                  .replace(item.variation_id, "")
                                  .replace(/[^\w\s+]/gi, "")
                                  .replace("+", "<br>"),
                              }}
                            />
                          </Button>
                        </Col>
                      );
                    })
                  ) : (
                    <p></p>
                  )}
                </Row>
              </Card.Body>
            </Card>
          </Col>
        </Row>
      </Container>
      <Container className="contwidth mt-3 mb-4">
        <Row>
          <Col xxl={12} lg={12} md={12} sm={12} xs={12}>
            <Card className="cardDisplay cardborder">
              <Card.Header className="cardheader">
                PLEASE SELECT PAYMENT METHOD
              </Card.Header>
              <Card.Body style={{ color: "#ffffff" }}>
                <Form layout="vertical" form={form} onFinish={handleSubmit}>
                  <Row>
                    <Col xl={6} md={6} sm={12}>
                      <Form.Item
                        label={
                          <label style={{ color: "#ffffff" }}>User ID:</label>
                        }
                        name={"userid"}
                        rules={[
                          {
                            required: true,
                            message: "User ID is requied!",
                          },
                        ]}
                      >
                        <Input
                          placeholder="12345678"
                          className="bgInput input"
                          size="large"
                        />
                      </Form.Item>
                    </Col>
                    <Col xl={6} md={6} sm={12}>
                      {" "}
                      <Form.Item
                        label={
                          <label style={{ color: "#ffffff" }}>Server ID:</label>
                        }
                        name={"server"}
                        rules={[
                          {
                            required: true,
                            message: "Server ID is requied!",
                          },
                        ]}
                      >
                        <Input
                          placeholder="(12345)"
                          size="large"
                          className="bgInput input"
                        />
                      </Form.Item>
                    </Col>
                  </Row>
                  <Row
                    style={{ borderBottom: "1px solid #490462" }}
                    className="mb-3 pb-3"
                  >
                    <Col xl={6} md={6} sm={12}>
                      {" "}
                      <label style={{ paddingBottom: 7, color: "#ffffff" }}>
                        Quality:
                      </label>
                      <Input
                        type="number"
                        name="qty"
                        value={value}
                        size="large"
                        className="bgInput input"
                        onChange={handleInputChange}
                      />
                    </Col>
                    <Col
                      xl={6}
                      md={6}
                      sm={12}
                      style={{
                        paddingTop: 30,
                        textAlign: "center",
                        color: "#ffffff",
                      }}
                    >
                      {" "}
                      <span className="mt-1">
                        <b>
                          <i>Total:</i>
                        </b>
                      </span>
                      <span className="total">
                        &nbsp;&nbsp;&nbsp;{price * value}
                      </span>
                      <span className="">&nbsp;USD</span>
                    </Col>
                  </Row>
                  <Row>
                    <Col
                      lg={6}
                      xxl={6}
                      md={12}
                      sm={12}
                      xs={12}
                      style={{ color: "#ffffff" }}
                    >
                      <div className="payborder">
                        <Row>
                          <Col lg={4} xxl={4} md={4} sm={4} xs={4}>
                            <Image src={ABA} style={{ width: "100%" }} />
                          </Col>
                          <Col
                            lg={8}
                            xxl={8}
                            md={8}
                            sm={8}
                            xs={8}
                            className="pt-2"
                          >
                            <h5>ABA PAY</h5>Scan to pay with ABA Mobile
                          </Col>
                        </Row>
                      </div>
                      <div className="payborder mt-2">
                        <Row>
                          <Col lg={4} xxl={4} md={4} sm={4} xs={4}>
                            <Image src={Khqr} style={{ width: "100%" }} />
                          </Col>
                          <Col
                            lg={8}
                            xxl={8}
                            md={8}
                            sm={8}
                            xs={8}
                            className="pt-2"
                          >
                            <h5>KHQR</h5>Scan to pay with member bank app
                          </Col>
                        </Row>
                      </div>
                    </Col>
                    <Col
                      lg={6}
                      xxl={6}
                      md={12}
                      sm={12}
                      xs={12}
                      style={{ color: "#ffffff", paddingTop: 10 }}
                    >
                      <h6 className=" doubleUnderline">NOTE:</h6>
                      When paying, please use the new QR CODE for payment as the
                      used QR CODE is not valid.
                      <h6 className="mt-2 doubleUnderline">
                        TERMS & CONDITIONS:
                      </h6>
                      <input
                        type="checkbox"
                        id="checkbox"
                        onClick={onCheckboxClick}
                        style={{ marginRight: 10 }}
                        className="cb1"
                      />
                      I agree to all orders and there is no cancellation or
                      refund
                      <Button
                        className="custom-btn btnFloat"
                        htmlType="submit"
                        style={{ color: "#ffffff", marginTop: 10 }}
                        size="large"
                        disabled={isDisabled}
                      >
                        Submit
                      </Button>
                    </Col>
                  </Row>
                </Form>
              </Card.Body>
            </Card>
          </Col>
        </Row>
      </Container>
    </div>
  );
}

export default AllDisplay;
