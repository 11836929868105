import axios from "axios";
// import CryptoJS from "crypto-js";

//MooGold Api that require the Token incompleted
export const config = {
  base_server: "https://api.sortadiamondstore.com/",
  image_path: "",
  version: "",
  token: "",
};
export const requestMooGoldToken = async (
  url = "",
  method = "get",
  data = {},
  new_token = null
) => {
  // var auth_basic = btoa(
  //   `${process.env.REACT_APP_USER_NAME}:${process.env.REACT_APP_PASSWORD}`
  // );
  // console.log(auth_basic);
  let result = "";
  // try {
  await axios({
    url: config.base_server + url,
    // withCredentials: true,
    method: method,
    data: data,
    headers: {
      // Authorization: `Basic ${auth_basic}`,
      "Content-Type": "application/json",
    },
  })
    .then((res) => {
      result = res.data;
    })
    .catch((error) => {
      result = error;
    });
  // } catch (err) {
  //   console.log(err);
  //   result = { message: "something want wrong!" };
  // }
  return result;
};
