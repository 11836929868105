import React, { useEffect, useState } from "react";
import "./AllgameStyle.css";
import { Container, Row, Col, Card } from "react-bootstrap";
import { Link, NavLink } from "react-router-dom";
import M1 from "../../assets/proImg/allgame/m1.jpg";
import M2 from "../../assets/proImg/allgame/m2.jpg";
import M3 from "../../assets/proImg/allgame/m3.jpg";
import M4 from "../../assets/proImg/allgame/m4.jpg";
import { requestMooGoldToken } from "../../share/mooGoldRequest";
function AllGameComponent() {
  const [list, setList] = useState([{}]);
  useEffect(() => {
    fetchData();
  }, []);
  const fetchData = async () => {
    try {
      var url = "product/list_product"; //"product/product_detail";
      var requestBody = JSON.stringify({
        path: "product/list_product",
        category_id: 50,
      });
      const result = await requestMooGoldToken(url, "POST", requestBody, null);
      setList(result);
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  return (
    <div>
      <Container className="contwidth " style={{ textAlign: "center" }}>
        <div className="seven mb-0 mt-0">
          <h1>ALL GAME TOP UP</h1>
        </div>
        <Row>
          {list.map((item, index) => {
            if (item.ID == 4427073) {
              return (
                <Col xxl={3} xl={3} lg={4} md={6} sm={6} xs={6} key={index}>
                  <Link to={"/display/" + item.ID}>
                    <Card
                      className="cardDisplay grow"
                      style={{
                        borderTopLeftRadius: 50,
                        borderTopRightRadius: 50,
                      }}
                    >
                      <Card.Img
                        variant="top"
                        src="https://cdn.moogold.com/2023/05/brawl-stars.jpg"
                        style={{ borderRadius: 50 }}
                      />
                      <Card.Body>
                        <Card.Text>{item.post_title}</Card.Text>
                      </Card.Body>
                    </Card>
                  </Link>
                </Col>
              );
            } else if (item.ID == 2134118) {
              return (
                <Col xxl={3} xl={3} lg={4} md={6} sm={6} xs={6} key={index}>
                  <Link to={"/display/" + item.ID}>
                    <Card
                      className="cardDisplay grow"
                      style={{
                        borderTopLeftRadius: 50,
                        borderTopRightRadius: 50,
                      }}
                    >
                      <Card.Img
                        variant="top"
                        src="https://cdn.moogold.com/2024/07/garena-free-fire.jpg"
                        style={{ borderRadius: 50 }}
                      />
                      <Card.Body>
                        <Card.Text>{item.post_title}</Card.Text>
                      </Card.Body>
                    </Card>
                  </Link>
                </Col>
              );
            } else if (item.ID == 99226) {
              return (
                <Col xxl={3} xl={3} lg={4} md={6} sm={6} xs={6} key={index}>
                  <Link to={"/display/" + item.ID}>
                    <Card
                      className="cardDisplay grow"
                      style={{
                        borderTopLeftRadius: 50,
                        borderTopRightRadius: 50,
                      }}
                    >
                      <Card.Img
                        variant="top"
                        src="https://cdn.moogold.com/2020/04/Hay-Day.jpg"
                        style={{ borderRadius: 50 }}
                      />
                      <Card.Body>
                        <Card.Text>{item.post_title}</Card.Text>
                      </Card.Body>
                    </Card>
                  </Link>
                </Col>
              );
            } else if (item.ID == 6963) {
              return (
                <Col xxl={3} xl={3} lg={4} md={6} sm={6} xs={6} key={index}>
                  <Link to={"/display/" + item.ID} style={{ borderRadius: 50 }}>
                    <Card
                      className="cardDisplay grow"
                      style={{
                        borderTopLeftRadius: 50,
                        borderTopRightRadius: 50,
                      }}
                    >
                      <Card.Img
                        variant="top"
                        src="https://cdn.moogold.com/2019/08/pubg.jpg"
                        style={{ borderRadius: 50 }}
                      />
                      <Card.Body>
                        <Card.Text>{item.post_title}</Card.Text>
                      </Card.Body>
                    </Card>
                  </Link>
                </Col>
              );
            } else if (item.ID == 4427071) {
              return (
                <Col xxl={3} xl={3} lg={4} md={6} sm={6} xs={6} key={index}>
                  <Link to={"/display/" + item.ID} style={{ borderRadius: 50 }}>
                    <Card
                      className="cardDisplay grow"
                      style={{
                        borderTopLeftRadius: 50,
                        borderTopRightRadius: 50,
                      }}
                    >
                      <Card.Img
                        variant="top"
                        src="https://cdn.moogold.com/2023/05/clash-of-clans.jpg"
                        style={{ borderRadius: 50 }}
                      />
                      <Card.Body>
                        <Card.Text>{item.post_title}</Card.Text>
                      </Card.Body>
                    </Card>
                  </Link>
                </Col>
              );
            } else if (item.ID == 2134118) {
              return (
                <Col xxl={3} xl={3} lg={4} md={6} sm={6} xs={6} key={index}>
                  <Link to={"/display/" + item.ID} style={{ borderRadius: 50 }}>
                    <Card
                      className="cardDisplay grow"
                      style={{
                        borderTopLeftRadius: 50,
                        borderTopRightRadius: 50,
                      }}
                    >
                      <Card.Img
                        variant="top"
                        src="https://cdn.moogold.com/2024/07/garena-free-fire.jpg"
                        style={{ borderRadius: 50 }}
                      />
                      <Card.Body>
                        <Card.Text>{item.post_title}</Card.Text>
                      </Card.Body>
                    </Card>
                  </Link>
                </Col>
              );
            } else if (item.ID == 5177311) {
              return (
                <Col xxl={3} xl={3} lg={4} md={6} sm={6} xs={6} key={index}>
                  <Link to={"/display/" + item.ID}>
                    <Card
                      className="cardDisplay grow"
                      style={{
                        borderTopLeftRadius: 50,
                        borderTopRightRadius: 50,
                      }}
                    >
                      <Card.Img
                        variant="top"
                        src="https://cdn.moogold.com/2023/09/honor-of-kings.jpg"
                        style={{ borderRadius: 50 }}
                      />
                      <Card.Body>
                        <Card.Text>{item.post_title}</Card.Text>
                      </Card.Body>
                    </Card>
                  </Link>
                </Col>
              );
            } else if (item.ID == 2314135) {
              return (
                <Col xxl={3} xl={3} lg={4} md={6} sm={6} xs={6} key={index}>
                  <Link to={"/display/" + item.ID} style={{ borderRadius: 50 }}>
                    <Card
                      className="cardDisplay grow"
                      style={{
                        borderTopLeftRadius: 50,
                        borderTopRightRadius: 50,
                      }}
                    >
                      <Card.Img
                        variant="top"
                        src="https://cdn.moogold.com/2022/11/super-sus.jpg"
                        style={{ borderRadius: 50 }}
                      />
                      <Card.Body>
                        <Card.Text>{item.post_title}</Card.Text>
                      </Card.Body>
                    </Card>
                  </Link>
                </Col>
              );
            } else if (item.ID == 4427072) {
              return (
                <Col xxl={3} xl={3} lg={4} md={6} sm={6} xs={6} key={index}>
                  <Link to={"/display/" + item.ID}>
                    <Card
                      className="cardDisplay grow"
                      style={{
                        borderTopLeftRadius: 50,
                        borderTopRightRadius: 50,
                      }}
                    >
                      <Card.Img
                        variant="top"
                        src="https://cdn.moogold.com/2023/05/clash-royale.jpg"
                        style={{ borderRadius: 50 }}
                      />
                      <Card.Body>
                        <Card.Text>{item.post_title}</Card.Text>
                      </Card.Body>
                    </Card>
                  </Link>
                </Col>
              );
            } else if (item.ID == 428075) {
              return (
                <Col xxl={3} xl={3} lg={4} md={6} sm={6} xs={6} key={index}>
                  <Link to={"/display/" + item.ID}>
                    <Card
                      className="cardDisplay grow"
                      style={{
                        borderTopLeftRadius: 50,
                        borderTopRightRadius: 50,
                      }}
                    >
                      <Card.Img
                        variant="top"
                        src="https://cdn.moogold.com/2020/10/genshin-impact-logo-revised.jpg"
                        style={{ borderRadius: 50 }}
                      />
                      <Card.Body>
                        <Card.Text>{item.post_title}</Card.Text>
                      </Card.Body>
                    </Card>
                  </Link>
                </Col>
              );
            } else if (item.ID == 15145) {
              return (
                <Col xxl={3} xl={3} lg={4} md={6} sm={6} xs={6} key={index}>
                  <Link to={"/display/" + item.ID}>
                    <Card
                      className="cardDisplay grow"
                      style={{
                        borderTopLeftRadius: 50,
                        borderTopRightRadius: 50,
                      }}
                    >
                      <Card.Img
                        variant="top"
                        src="https://cdn.moogold.com/2019/11/mobile-legends-bang-bang.jpg"
                        style={{ borderRadius: 50 }}
                      />
                      <Card.Body>
                        <Card.Text>{item.post_title}</Card.Text>
                      </Card.Body>
                    </Card>
                  </Link>
                </Col>
              );
            } else if (item.ID == 129024) {
              return (
                <Col xxl={3} xl={3} lg={4} md={6} sm={6} xs={6} key={index}>
                  <Link to={"/display/" + item.ID}>
                    <Card
                      className="cardDisplay grow"
                      style={{
                        borderTopLeftRadius: 50,
                        borderTopRightRadius: 50,
                      }}
                    >
                      <Card.Img
                        variant="top"
                        src="https://cdn.moogold.com/2024/07/garena-free-fire.jpg"
                        style={{ borderRadius: 50 }}
                      />
                      <Card.Body>
                        <Card.Text>{item.post_title}</Card.Text>
                      </Card.Body>
                    </Card>
                  </Link>
                </Col>
              );
            } else if (item.ID == 5856004) {
              return (
                <Col xxl={3} xl={3} lg={4} md={6} sm={6} xs={6} key={index}>
                  <Link to={"/display/" + item.ID}>
                    <Card
                      className="cardDisplay grow"
                      style={{
                        borderTopLeftRadius: 50,
                        borderTopRightRadius: 50,
                      }}
                    >
                      <Card.Img
                        variant="top"
                        src="https://cdn.moogold.com/2023/11/zepeto.jpg"
                        style={{ borderRadius: 50 }}
                      />
                      <Card.Body>
                        <Card.Text>{item.post_title}</Card.Text>
                      </Card.Body>
                    </Card>
                  </Link>
                </Col>
              );
            }
          })}
        </Row>
      </Container>
    </div>
  );
}

export default AllGameComponent;
