import React, { useState } from "react";
import "./NavStyle.css";
import { NavLink } from "react-router-dom";
import { Container, Nav, Navbar, Image, Button, Modal } from "react-bootstrap";
import { List } from "react-bootstrap-icons";
import Logo from "../../assets/img/logosorta.jpg";

function NavBar() {
  const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  return (
    <div>
      <Navbar collapseOnSelect expand="lg" className="bgnav">
        <Container className="contwidth">
          <Navbar.Brand href="#home">
            <Image
              alt=""
              src={Logo}
              width="130"
              height="auto"
              className="d-inline-block align-top"
            />{" "}
          </Navbar.Brand>

          <Navbar id="responsive-navbar-nav">
            <Nav className="me-auto"></Nav>
            <Nav>
              <NavLink to="/" className=" textNav">
                ALL GAME
              </NavLink>
            </Nav>
          </Navbar>
        </Container>
      </Navbar>
      <Modal
        show={show}
        onHide={handleClose}
        backdrop="static"
        keyboard={false}
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title>Modal title</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          I will not close if you click outside me. Do not even try to press
          escape key.
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose}>
            Close
          </Button>
          <Button variant="primary">Understood</Button>
        </Modal.Footer>
      </Modal>
    </div>
  );
}

export default NavBar;
