import "./App.css";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import "bootstrap/dist/css/bootstrap.min.css";
import HomePage from "./pages/HomePage";
import NavBar from "./component/navbar/NavBar";
import FooterComponent from "./component/footer/FooterComponent";
import MobilePage from "./pages/mobile/MobilePage";
import PcPage from "./pages/pc/PcPage";
import AllDisplayPage from "./pages/alldisplay/AllDisplayPage";
function App() {
  return (
    <div className="body">
      <BrowserRouter>
        <NavBar />
        <Routes>
          <Route path="/" element={<HomePage />} />
          <Route path="/mobile" element={<MobilePage />} />
          <Route path="/pc" element={<PcPage />} />
          <Route path="/display/:id" element={<AllDisplayPage />} />
        </Routes>
        <FooterComponent />
      </BrowserRouter>
    </div>
  );
}

export default App;
