import React from "react";
import SlideComponent from "../../component/slide/SlideComponent";
import MobileComponent from "../../component/mobilegame/MobileComponent";
function MobilePage() {
  return (
    <div>
      <SlideComponent />
      <MobileComponent />
    </div>
  );
}

export default MobilePage;
