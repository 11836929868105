import React from "react";
import PcComponent from "../../component/pcgame/PcComponent";
import "./PcStyle.css";
import SlideComponent from "../../component/slide/SlideComponent";
function PcPage() {
  return (
    <div>
      <SlideComponent />
      <PcComponent />
    </div>
  );
}

export default PcPage;
