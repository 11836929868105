import React from "react";
import SlideComponent from "../component/slide/SlideComponent";
//import TopGamePage from "../component/topgame/TopGamePage";
import AllGameComponent from "../component/allgame/AllGameComponent";
import "./HomeStyle.css";
function HomePage() {
  return (
    <div>
      <SlideComponent />
      <AllGameComponent />
    </div>
  );
}

export default HomePage;
